import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { ActionFunction, LoaderFunctionArgs, MetaFunction, json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useActionData,
  useLoaderData,
  useLocation,
  useMatches,
  useSearchParams,
  useRouteError,
} from "@remix-run/react";
import "./globals.css";
import "./themes.css";
import { createUserSession, getUserInfo } from "./utils/session.server";
import { useRootData } from "./utils/data/useRootData";
import { loadRootData } from "./utils/data/.server/rootData";
import FloatingLoader from "./components/ui/loaders/FloatingLoader";
import CookieConsentBanner from "./components/cookies/CookieConsentBanner";
import { allCookieCategories } from "./application/cookies/ApplicationCookies";
import { CookieCategory } from "./application/cookies/CookieCategory";
import { Fragment, useEffect, useState } from "react";
import AnalyticsHelper from "./utils/helpers/AnalyticsHelper";
import CookieHelper from "./utils/helpers/CookieHelper";
import clsx from "clsx";
import { getUser, updateUserProfile } from "./utils/db/users.db.server";
import ServerError from "./components/ui/errors/ServerError";
import PageMaintenanceMode from "./components/pages/PageMaintenanceMode";
import FeatureFlagTracker from "./modules/featureFlags/components/FeatureFlagTracker";
import { serverTimingHeaders } from "./modules/metrics/utils/defaultHeaders.server";
import BannerBlock from "./modules/pageBlocks/components/blocks/marketing/banner/BannerBlock";
import { useTranslation } from "react-i18next";
import { Toaster as ReactHostToaster } from "react-hot-toast";
import { Toaster as SonnerToaster } from "./components/ui/sonner";
import { useChangeLanguage } from "remix-i18next";
import EventsService from "./modules/events/services/.server/EventsService";
import { UserPreferencesUpdatedDto } from "./modules/events/dtos/UserPreferencesUpdatedDto";
import AuthService from "./modules/users/services/AuthService";
import ScriptInjector from "./modules/shared/scripts/ScriptInjector";
import ScriptRewardful from "./modules/shared/scripts/ScriptRewardful";
import ScriptCrisp from "./modules/shared/scripts/ScriptCrisp";
import ScriptAnalytics from "./modules/shared/scripts/ScriptAnalytics";
import { getTranslations } from "./locale/i18next.server";
import { getAnalyticsInfo } from "./utils/analyticsCookie.server";
import FeedbackServer from "./modules/helpDesk/services/Feedback.server";
export { serverTimingHeaders as headers };

export const handle = { i18n: "translations" };
export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  return loadRootData({ request, params });
};

export const meta: MetaFunction<typeof loader> = ({ data }) => data?.metatags ?? [];

function Document({ children, lang = "en", dir = "ltr" }: { children: React.ReactNode; lang?: string; dir?: string }) {
  const { t } = useTranslation();
  const location = useLocation();
  const rootData = useRootData();
  const [lastLocation, setLastLocation] = useState("");
  const matches = useMatches();
  const actionData = useActionData();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (lastLocation == location.pathname) {
      return;
    }
    const routeMatch = matches.find((m) => m.pathname == location.pathname);
    setLastLocation(location.pathname);

    async function addView() {
      let params = searchParams ? "?" + searchParams.toString() : "";
      AnalyticsHelper.addPageView({
        url: location.pathname + params,
        route: routeMatch?.id,
        rootData,
      });
    }
    addView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLocation, location, rootData, actionData]);

  return (
    <html
      lang={lang}
      dir={dir}
      key={rootData.userSession?.lng}
      className={
        // ["/app/", "/admin/"] don't apply the dark theme
        // rootData.userSession?.lightOrDarkMode === "dark" ? "dark" : ""
        ["/app/", "/admin/", "/docs", "/chatbot/", "/chatbot-widget"].some((p) => location.pathname.startsWith(p))
          ? ""
          : rootData.userSession?.lightOrDarkMode === "dark"
          ? "dark"
          : ""
      }
    >
      <head>
        <Meta />
        {rootData.appConfiguration?.branding?.favicon ? (
          <link rel="icon" href={rootData.appConfiguration?.branding?.favicon} />
        ) : (
          <>
            <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          </>
        )}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        {rootData.appConfiguration?.auth.recaptcha.enabled && <script src="https://www.google.com/recaptcha/api.js" async defer></script>}
        {["/"].some((p) => location.pathname === p) && (
          <Fragment>
            {rootData.appConfiguration?.reviews?.trustpilot && (
              <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
            )}
            <script src="https://js.storylane.io/js/v1/storylane.js"></script>
          </Fragment>
        )}
        {/* <script src="http://localhost:3000/embed.js" data-chatbot-id="clwhm19g000vk78eousiw0uhw" data-open-delay="-1" defer async /> */}
        {/* <script
          src="/embed.js"
          data-api-url="http://localhost:3000"
          data-chatbot-id="clw6txr8z0042w1k8ebmngdg7"
          data-thread-id="clwgxext0004nqbwrp0mud0v9"
          data-open-delay="-1"
          data-verbose="true"
          defer
          async
        /> */}

        {/* <script src="http://localhost:3000/embed.js" data-chatbot-id="clw6txr8z0042w1k8ebmngdg7" data-open-delay="-1" defer async data-verbose="true" /> */}

        {/* <script
          src="https://datalang.vercel.app/embed.js"
          data-api-url="https://datalang.vercel.app"
          data-chatbot-id="clw6txr8z0042w1k8ebmngdg7"
          data-thread-id="clwgxext0004nqbwrp0mud0v9"
          data-open-delay="-1"
          data-verbose="true"
          defer
          async
        /> */}
        {/* <script src="https://datalang.vercel.app/embed.js" data-chatbot="clwa4soe302sbxuu0vmpxbpqs" defer async /> */}

        {!rootData.debug && !["/app", "/admin", "/chatbot"].some((p) => location.pathname.startsWith(p)) && (
          <Fragment>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16577911538"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-16577911538');
          `,
              }}
            ></script>
            <ScriptRewardful />
          </Fragment>
        )}
        <Links />
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
window.embeddedChatbotConfig = {
chatbotId: "CGQIW8NTV22Sv0asl9HuK",
domain: "www.chatbase.co"
}
`,
          }}
        />
        <script src="https://www.chatbase.co/embed.min.js" chatbotId="CGQIW8NTV22Sv0asl9HuK" domain="www.chatbase.co" defer></script> */}
      </head>

      <body
        className={clsx(
          `theme-${rootData.theme}`,
          "bg-background text-foreground max-h-full min-h-screen max-w-full"
          // location.pathname.startsWith("/app") || location.pathname.startsWith("/admin") ? "bg-slate-900" : "bg-white dark:bg-slate-900"
        )}
      >
        {rootData.impersonatingSession && (
          <BannerBlock
            item={{
              style: "top",
              text: t("account.session.impersonating", { 0: rootData.impersonatingSession.toUser.email, 1: rootData.impersonatingSession.fromUser.email }),
              cta: [{ text: t("account.session.logout"), href: "/logout" }],
            }}
          />
        )}
        {rootData.featureFlags?.includes("maintenance") && !location.pathname.startsWith("/admin") && !location.pathname.startsWith("/login") ? (
          <FeatureFlagTracker flag="maintenance">
            <PageMaintenanceMode />
          </FeatureFlagTracker>
        ) : (
          <Fragment>
            {!["/app", "/admin", "/docs"].some((p) => location.pathname.startsWith(p)) && <BannerBlock />}
            {children}
          </Fragment>
        )}

        {/* <script
          src="https://cdn.jsdelivr.net/gh/datalangai/embed@main/chat-widget.umd.js"
          data-api-url="https://datalangai.com/api/chat"
          data-theme-color="#0000ff"
          data-welcome-message="Hello! How can I help you?"
          data-chatbot-id="your-chatbot-id"
        /> */}

        {/* <script
          src="/chat-widget.umd.js"
          data-api-url="https://your-api-url.com/api/chat"
          data-theme-color="#0000ff"
          data-welcome-message="Hello! How can I help you?"
          data-chatbot-id="your-chatbot-id"
          defer
          async
        ></script> */}

        {!["/chatbot"].some((p) => location.pathname.startsWith(p)) && (
          <Fragment>
            <ScriptInjector scripts={rootData.appConfiguration?.scripts} />
            <ScriptAnalytics />
            <CookieConsentBanner />
          </Fragment>
        )}

        {["/app"].some((p) => location.pathname.startsWith(p)) && <ScriptCrisp />}

        <FloatingLoader />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const action: ActionFunction = async ({ request }) => {
  const { t } = await getTranslations(request);
  const userInfo = await getUserInfo(request);
  const form = await request.formData();
  const action = form.get("action");
  const redirect = form.get("redirect")?.toString();
  if (action === "toggleLightOrDarkMode") {
    const current = userInfo.lightOrDarkMode ?? "dark";
    const lightOrDarkMode = current === "dark" ? "light" : "dark";
    return createUserSession(
      {
        ...userInfo,
        lightOrDarkMode,
      },
      redirect
    );
  } else if (action === "setLocale") {
    const lng = form.get("lng")?.toString() ?? "";
    if (userInfo.userId) {
      const user = await getUser(userInfo.userId);
      if (user) {
        await updateUserProfile({ locale: lng }, user.id);
        await EventsService.create({
          request,
          event: "user.preferences.updated",
          tenantId: null,
          userId: user.id,
          data: {
            old: { locale: user.locale ?? "" },
            new: { locale: lng },
            user: { id: user.id, email: user.email },
          } satisfies UserPreferencesUpdatedDto,
        });
      }
    }
    return createUserSession(
      {
        ...userInfo,
        lng,
      },
      redirect
    );
  } else if (action === "setCookieConsent") {
    const preserveParams = ["ref", "source", "utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
    const allowed = form.getAll("cookies[]").map((f) => f.toString());
    const cookies: { category: string; allowed: boolean }[] = [];
    allCookieCategories.forEach((item) => {
      const categoryName = CookieCategory[item];
      const isAllowed = allowed.filter((f) => f.toString() === categoryName.toString()).length > 0;
      cookies.push({ category: CookieCategory[item], allowed: isAllowed ?? item === CookieCategory.REQUIRED });
    });
    const searchParams = new URLSearchParams();
    preserveParams.forEach((param) => {
      const value = form.get(param)?.toString();
      if (value) {
        searchParams.set(param, value);
      }
    });
    return createUserSession(
      {
        ...userInfo,
        cookies,
      },
      redirect + "?" + searchParams.toString()
    );
  } else if (action === "add-feedback") {
    if (!userInfo.userId) {
      return json({ error: t("feedback.notLoggedIn") }, { status: 400 });
    }
    const message = form.get("message")?.toString() || "";
    const fromUrl = form.get("fromUrl")?.toString() || "";
    const tenantId = form.get("tenantId")?.toString() || null;
    if (!message) {
      return json({ error: "Message is required" }, { status: 400 });
    }
    const user = await getUser(userInfo.userId);
    const analyticsInfo = await getAnalyticsInfo(request);
    if (!user) {
      return json({ error: "User not found" }, { status: 400 });
    }
    try {
      await FeedbackServer.send({
        t,
        type: "user",
        tenantId,
        userId: userInfo.userId,
        message,
        fromUrl,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        userAnalyticsId: analyticsInfo.userAnalyticsId,
      });
      return json({ success: t("feedback.sent") });
    } catch (e: any) {
      return json({ error: e.message }, { status: 400 });
    }
  }
  if (action === "login") {
    return await AuthService.loginFromRequest(request, form);
  }
  if (action === "setTheme") {
    const theme = form.get("theme")?.toString();
    if (!theme) {
      return json({ error: "Invalid theme" });
    }
    return createUserSession({ ...userInfo, theme }, redirect);
  }
};

function App() {
  const { locale } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();
  const rootData = useRootData();
  useChangeLanguage(locale);
  return (
    <Document lang={locale} dir={i18n.dir()}>
      <Outlet />
      <ReactHostToaster />
      <SonnerToaster />
      {/* <CheckI18n /> */}
      <ScriptInjector scripts={rootData.appConfiguration?.scripts} />
    </Document>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <Document>
      <div className="mx-auto p-12 text-center">
        <ServerError />
      </div>
    </Document>
  );
}
